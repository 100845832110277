<template>
  <div class="page">
    <h1>価格表</h1>
    <div class="content">
      <table class="border">
        <thead>
          <tr>
            <th rowspan="2"></th>
            <th rowspan="2">
              <span>匿名</span>
              <span>ユーザー</span>
            </th>
            <th colspan="3">
              <span>ログイン</span>
              <span>ユーザー</span>
            </th>
          </tr>
          <tr>
            <th>
              <span>無料</span>
              <span>プラン</span>
            </th>
            <th>
              <span>標準</span>
              <span>プラン</span>
            </th>
            <th>
              <span>プレミアム</span>
              <span>プラン</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span>価格</span>
              <span class="annotation">※１</span>
            </td>
            <td>
              <span>￥０</span>
              <span>／月</span>
            </td>
            <td>
              <span>￥０</span>
              <span>／月</span>
            </td>
            <td>
              <span>￥３５０</span>
              <span>／月</span>
            </td>
            <td>
              <span>￥１１５０</span>
              <span>／月</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>漢字</span>
              <span>プリント</span>
              <span>の</span>
              <span>作成</span>
            </td>
            <td>無制限</td>
            <td>無制限</td>
            <td>無制限</td>
            <td>無制限</td>
          </tr>
          <tr>
            <td>
              <span>学習</span>
              <span>プリント</span>
              <span>の</span>
              <span>共有</span>
              <span class="annotation">※２</span>
            </td>
            <td>無制限</td>
            <td>無制限</td>
            <td>無制限</td>
            <td>無制限</td>
          </tr>
          <tr>
            <td>
              <span>手書き</span>
              <span>学習</span>
              <span>の</span>
              <span>開始</span>
            </td>
            <td>無制限</td>
            <td>無制限</td>
            <td>無制限</td>
            <td>無制限</td>
          </tr>
          <tr>
            <td>
              <span>手書き</span>
              <span>文字の</span>
              <span>自動判定</span>
              <span class="annotation">※３</span>
            </td>
            <td>
              <span>５回</span><span>／分</span><br>
              <span>５０回</span><span>／日</span>
            </td>
            <td>
              <span>１０回</span><span>／分</span><br>
              <span>１００回</span><span>／日</span>
            </td>
            <td>
              <span>無制限</span><span>／分</span><br>
              <span>１０００回</span><span>／日</span>
            </td>
            <td>
              <span>無制限</span><span>／分</span><br>
              <span>１００００回</span><span>／日</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>自動</span>
              <span>スケジュール</span>
            </td>
            <td>無制限</td>
            <td>無制限</td>
            <td>無制限</td>
            <td>無制限</td>
          </tr>
        </tbody>
      </table>
      <ul>
        <li>
          ※１ メールにてお送りする 「お支払い用のリンク」から、クレジットカード 等の お支払いがご利用いただけます。
          お支払いが完了してから２４時間以内に、お支払いいただいたプランへと移行いたします。
        </li>
        <li>
          ※２ 学習内容をＵＲＬに埋め込んだリンクにて、ご自由に共有いただけます。
        </li>
        <li>
          ※３ 協定世界時（ＵＴＣ）時刻における「分ごと」および「日ごと」の いずれかの回数が超過すると、
          「次の分」または「次の日」が始まるまで、該当する機能が制限されます。</li>
        <li>
          ※ 回数制限なしでの利用をご希望の方は、従量課金のお見積りをお出しいたしますので、ご連絡ください。
        </li>
      </ul>
    </div>
    <p class="next-comment">
      「
      <router-link to="/service/print/policy/payment/">お支払い</router-link>
      」は 簡単です<br>
      まずは <b>無料</b>で 「
      <a href="https://print.mihiraku.com/" target="_blank" rel="noopener noreferrer">お試し</a>
      」ください<br>
    </p>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
